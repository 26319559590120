.showcaseContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-top: 50px;
}
.reverse {
    flex-direction: row-reverse;
}

.imageSection {
    width: 50%;
}

.showcaseImage {
    width: 100%;
    border-radius: 10px;
}

.contentSection {
    width: 50%;
    padding: 0 40px;
    color: #ffffff;
}

.title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #ffffff;
}

.description {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
    color: #b0b3b8;
}

.features {
    list-style: none;
    padding: 0;
    margin-bottom: 30px;
}

.features li {
    font-size: 18px;
    margin-bottom: 10px;
}

.requestButton {
    background-color: white;
    border: none;
    padding: 15px 30px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: #0b1216;
    transition: 0.3s;
    border: 1px solid white;
}
.requestButton:hover {
    background-color: black;
    color: white;
}
.features span {
    color: white;
}

@media (max-width: 768px) {
    .showcaseContainer {
        flex-direction: column;
        padding-bottom: 20px;
    }

    .imageSection,
    .contentSection {
        width: 100%;
    }
    .contentSection {
        padding: 0px;
    }
}
