.before {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.before img,
.after img {
    width: 100%;
}
.beforeDis {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    /* width: 1000px; */
}
.before video {
    width: 50%;
}
.arrow {
    width: 80px;
}
.beforeSection h3 {
    text-align: center;
    font-size: 24px;
    color: white;
    font-weight: 400;
}
.beforeSection {
    padding: 50px 0px;
}
@media (max-width: 768px) {
    .beforeDis {
        flex-direction: column;
    }
    .before,
    .after {
        width: 100%;
    }
    .after iframe {
        width: 100% !important;
    }
    .before video {
        height: fit-content;
        width: 80%;
    }
}
