.header {
    position: fixed;
    top: 20px;
    left: 0;
    right: 0;
    width: 90%;
    margin: auto;
    padding: 15px 40px;
    transition: background-color 0.3s ease, padding 0.3s ease, top 0.3s ease; /* Added top for smooth hide/show */
    border: 1px solid white;
    border-radius: 50px;
    z-index: 9999; /* Keep header on top */
}

.header.hidden {
    top: -100px; /* Hides the header when scrolling down */
}

.header.visible {
    top: 20px; /* Shows the header when scrolling up */
}

.header.scrolled {
    background-color: black;
}

/* Navbar container */
.navDis {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
}

.header.scrolled {
    background-color: black;
    /* padding: 5px 20px; */
}

/* Logo and Menu */
.logo {
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 20px;
}

.logo img {
    height: 25px;
    display: block;
}

.desktopMenu {
    display: flex;
    gap: 20px;
}

.desktopMenu li {
    position: relative;
}

.desktopMenu li a {
    text-decoration: none;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: color 0.3s ease;
}

.desktopMenu li a:hover {
    color: lightblue;
}

/* Left section of header */
.leftSide ul {
    padding: 0px 20px;
    border-left: 1px solid white;
}

.leftSide {
    display: flex;
    align-items: center;
}

/* Hamburger Menu */
.hamburger {
    display: none;
    color: white;
}

/* Search section */
.search {
    padding-left: 20px;
    border-left: 1px solid white;
}

.search img {
    width: 20px;
    height: 20px;
}

.search-input {
    border: 1px solid #ddd;
    background-color: black;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
}

.contactBtn {
    background-color: white;
    padding: 7px 25px;
    border-radius: 4px;
    border: 1px solid white;
    color: #0e1a1f !important;
    transition: 0.3s !important;
}
.contactBtn:hover {
    background-color: black;
    color: white !important;
}
/* Mobile Nav (outside of header) */
.nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: black;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 999; /* Lower z-index than header */
}

.nav.open {
    transform: translateX(0);
}

.nav ul {
    flex-direction: column;
    gap: 40px;
}

/* Search Input */
.searchInput {
    border: 1px solid #ddd;
    background-color: black;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .hamburger {
        display: block;
        z-index: 10000; /* Highest z-index to ensure hamburger is on top */
    }
    .nav {
        padding-top: 150px;
    }
    .nav ul {
        display: flex;
        flex-direction: column;
    }
    .nav a {
        color: white;
    }
    .contactBtn {
        width: 100%;
        display: block;
        padding: 13px;
        font-weight: 600;
        text-align: center;
    }
    .nav ul {
        padding-left: 0px;
        width: 90%;
        margin: auto;
    }
    .logo img {
        height: 20px;
    }

    .header {
        width: 90%;
        margin: auto;
        padding: 15px 25px;
        z-index: 9999;
    }
    .desktopMenu {
        display: none;
    }
}
