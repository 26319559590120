.swiperDis {
    /* width: 20%; */
    display: flex;
    padding-bottom: 40px;
    /* align-items: center; */
}
.features h2 {
    color: white;
    text-align: center;
    font-size: 32px;
}
.swiperDis img {
    filter: brightness(0) saturate(100%) invert(100%);
}
@media (max-width: 768px) {
    .features h2 {
        color: white;
        text-align: center;
        font-size: 24px;
    }
}
